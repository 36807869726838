* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.anyflexbox body, .anyflexbox .content, .anyflexbox .content .wrap, .anyflexbox .main {
  display: block;
  flex: none; }

@media (min-width: 1000px) {
  .page--banner .banner {
    padding-top: 3em;
    padding-bottom: 3em; }

  .main__header {
    padding-bottom: 0; } }
.multi-step body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.42857143em;
  color: #333; }

.multi-step h1, .multi-step h2 {
  font-family: "Museo Slab","Slabo 13px",serif;
  -webkit-font-smoothing: antialiased; }

.multi-step .logo__image--large {
  max-height: none; }

@media (min-width: 768px) {
  .multi-step .logo__image--large {
    max-height: 12em; } }
.multi-step .page--banner .banner {
  padding-top: 3em;
  padding-bottom: 3em; }

.multi-step .wrap {
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 768px) {
  .multi-step .wrap {
    width: 750px; } }
@media (min-width: 992px) {
  .multi-step .wrap {
    width: 970px; } }
@media (min-width: 1200px) {
  .multi-step .wrap {
    width: 1170px; } }
@media (min-width: 1000px) {
  .multi-step .main, .multi-step .sidebar {
    padding-left: 15px !important;
    padding-right: 15px !important; }

  .multi-step .main {
    width: 58.33333333%;
    padding-left: 0px; }

  .multi-step .sidebar {
    width: 41.66666667%;
    padding-right: 0px; }

  .multi-step .sidebar__content {
    background: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    padding: 30px;
    padding-bottom: 10px; }

  .multi-step .order-summary__sections {
    display: block;
    flex: none;
    height: auto;
    width: auto; }

  .multi-step .order-summary__section--product-list .product-table {
    margin-top: 0; } }
.multi-step .content {
  padding-top: 45px;
  background: #fbf4e4; }

.multi-step .page--banner .main, .multi-step .page--banner .sidebar {
  padding-top: 0px;
  margin-bottom: 30px; }

.multi-step .breadcrumb {
  margin-bottom: 30px; }

.multi-step .main .field__input, .multi-step .input-checkbox, .multi-step .input-radio {
  background: white; }

.multi-step .payment-due__price {
  font-family: "Museo Slab","Slabo 13px",serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased; }

.multi-step .btn {
  font-family: "Museo Slab","Slabo 13px",serif;
  border: 0 none;
  padding: 12px 30px;
  border-radius: 0;
  background: #5cb85c;
  color: #ffffff;
  width: 200px;
  font-size: 18px;
  text-transform: uppercase;
  position: relative;
  font-weight: normal; }

.multi-step .btn:after {
  content: '';
  border: 2px solid white;
  position: absolute;
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px; }

.multi-step .step__footer__continue-btn .btn__content {
  display: none; }

.multi-step .step__footer__continue-btn:before {
  content: 'Continue';
  display: block; }

.multi-step .os-header {
  padding-left: 60px; }

@media (min-width: 1300px) {
  .multi-step .os-header__hanging-icon {
    right: auto;
    left: 0; } }
/*# sourceMappingURL=checkout.scss.liquid.map */
